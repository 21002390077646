import  { useEffect, useState } from 'react';
import './App.css';
import ServerStatus, { Serverstate } from './ServerStatus';


function App() {
  const [backendStatus, setBackendStatus] = useState<Serverstate>();
  const [frontendStatus, setFrontendStatus] = useState<Serverstate>();
  const [overrideFrontendStatus, setOverrideFrontendStatus] = useState<Serverstate>();

  useEffect(() => {
    fetch("https://whatscookn.cloud:444/api")
      .then((response) => {
        if (response.status === 200) {
          setBackendStatus(Serverstate.up);
          setFrontendStatus(Serverstate.up);
          return response.json();
        } else if (response.status === 429) {
          setFrontendStatus(undefined);
        }
        else {
          throw Error('down')
        }
      }).then((body) => {
        console.log("body", body)
        if (body.message)
          setOverrideFrontendStatus(Serverstate.work)
      })
      .catch((error) => {
        setBackendStatus(Serverstate.down);
      });

  }, [])





  return (
    <div className="App">
      <h1>WhatsCookn Domain Status</h1>

      <ServerStatus serverName="Frontend Server" status={overrideFrontendStatus ? overrideFrontendStatus : frontendStatus} />
      <ServerStatus serverName="Backend Server" status={backendStatus} />

    </div>
  );
}

export default App;
