// src/components/ServerStatus.js

import React, { useEffect, useMemo, useState } from 'react';

export enum Serverstate {'up','down', 'work'}

type Props = {
    serverName: string;
    status?: Serverstate;
};

const ServerStatus = (props: Props) => {
    const { serverName, status } = props;
   
    const visibleStatus= useMemo(()=>{

        switch(status){
            case Serverstate.up:
            return 'UP ✔️';

            case  Serverstate.down:
            return 'DOWN ❌';
            
            case  Serverstate.work:
            return '🚧 Under maintenance 🚧';

            default:
                return 'Checking...';
        }

    },[status])


    return (
        <div className={`server-status ${(status===Serverstate.up) ? 'up' : 'down'}`}>
            <p>{serverName}</p>
            <p className="status">{visibleStatus}</p>
        </div>
    );
};

export default ServerStatus;
